// eslint-disable-next-line import/no-unresolved
import { createOneGraphClientOverrideDeprecation, gql } from '@oneaudi/onegraph-client';
import type { DealerData } from '../interfaces/header-components.interfaces';

const QUERY_BY_DEALER_ID = gql`
  query Dealer($dealerId: String!) {
    dealerById(dealerId: $dealerId) {
      name
      url
      additionalData {
        displayName
      }
      dealerId
      services
      address
      phone
      openingHours {
        openingHoursFormatted
        openingHoursNote
        departments {
          id
          departmentName
          departmentOpeningHoursNote
          openingHours {
            id
            open
            timeRanges {
              openTime
              closeTime
            }
          }
        }
      }
    }
  }
`;

const metaValues: any = {};
export const getMetaContent = (dom: Element, metaName: string): string => {
  if (!metaValues[metaName] || dom !== metaValues[metaName].dom) {
    const metatag = dom.querySelector(`meta[name=${metaName}]`);
    metaValues[metaName] = {
      dom,
      value: metatag?.getAttribute('content') || '',
    };
  }
  return metaValues[metaName].value;
};

export const getClientId = (): string | null => {
  return getMetaContent(document.head, 'oadd-client-id') || null;
};

export async function getDealerData(
  endpoint: string | undefined,
  dealerId: string | undefined,
): Promise<DealerData | undefined> {
  if (dealerId === undefined) {
    if (__DEBUG__) {
      // eslint-disable-next-line no-console
      console.warn('[nemo-header|dealer-data] no Dealer ID found');
    }
    return undefined;
  }

  const clientId = getClientId();

  if (endpoint !== undefined && clientId) {
    const client = createOneGraphClientOverrideDeprecation({
      endpoint,
      clientName: __FEATURE_APP_NAME__,
      clientVersion: __FEATURE_APP_VERSION__,
      customHeaders: {
        clientId,
      },
    });
    const result = await client.query<DealerData>({
      query: QUERY_BY_DEALER_ID,
      variables: {
        dealerId,
      },
    });
    return result.data;
  }
  if (__DEBUG__) {
    // eslint-disable-next-line no-console
    console.warn('[nemo-header|dealer-data] no graphql endpoint or clientId provided');
  }

  return undefined;
}
