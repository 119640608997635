import { MAX_LENGTH_DEALER_NAME } from './globals';

export const upperCase = (s: string | undefined): string => {
  return s ? s.charAt(0).toUpperCase() + s.slice(1) : '';
};

export const truncate = (name: string | undefined): string | undefined => {
  return name !== undefined && name.length >= MAX_LENGTH_DEALER_NAME
    ? `${name?.substring(0, MAX_LENGTH_DEALER_NAME - 1)}…`
    : name || undefined;
};
