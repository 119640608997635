import { ContentFragment } from '@oneaudi/falcon-tools';
import HeaderResponse, {
  MenuItem,
  Search as LegacySearch,
  Link as LegacyLink,
  Teaser as LegacyTeaser,
  LoginLink,
  Suppliers,
} from '../interfaces/header-response.interfaces';
import { AudiHeaderConfig } from '../interfaces/header-components.interfaces';

export type FalconConfig = {
  // same identifier as for the content
  __type?: 'aem-headless';

  // Note: this is not required by default. It may only be required if you
  // override the options, so that the feature app config is required on your root CFM
  config?: Partial<ContentFragment> & {
    fields: AudiHeaderConfig;
  };
};

type Login = {
  fields: LoginLink;
};

type Search = {
  fields: LegacySearch;
};

type Link = {
  fields: {
    IsAudiSport: boolean;
    Text: string;
    Url: string;
    Type: string;
    Target: string;
  };
};

type TeserLink = {
  fields: {
    Text: string;
    Url: string;
    Type: string;
    Target: string;
  };
};

type Teaser = {
  fields: {
    Image: {
      path: string;
      assetId: string;
      created: string;
      height: number;
      mimeType: string;
      sizeInBytes: number;
      status: string;
      width: number;
    };
    ImageAltText: string;
    Link: TeserLink;
  };
};

export type MainNavigationType = {
  fields: {
    Link: Link;
    SubNavigation: Link[];
    Teaser: Teaser;
  };
};

export type SuppliersDetails = {
  fields: {
    Name: string;
    LinkUrl: string;
    LinkLabel: string;
  };
};

export type ContentHeadless = {
  __type: string;
  fields: {
    MenuLabel: string;
    LogoText: string;
    LogoUrl: string;
    Search?: Search;
    MainNavigation: MainNavigationType[];
    Login?: Login;
    Suppliers?: SuppliersDetails[];
  };
};

export function normalizeConfig(
  config?: AudiHeaderConfig | FalconConfig,
): AudiHeaderConfig | undefined {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (config && config?.__type === 'aem-headless') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return config?.config?.fields as AudiHeaderConfig;
  }
  return config as AudiHeaderConfig;
}

export function normalizeContentHeadless(
  headless: ContentHeadless,
  config?: AudiHeaderConfig,
): HeaderResponse {
  const MainNavigation: MenuItem[] = [];
  headless.fields.MainNavigation.forEach((item) => {
    const SubNavigation: LegacyLink[] = [];
    item.fields.SubNavigation?.forEach((sub) => SubNavigation.push(sub.fields));
    const Teaser: LegacyTeaser = {
      Image: {
        Alt: `${item.fields.Teaser?.fields.ImageAltText}`,
        Height: `${item.fields.Teaser?.fields.Image.height}`,
        Width: `${item.fields.Teaser?.fields.Image.width}`,
        Src: `${item.fields.Teaser?.fields.Image.path}`,
      },
      Link: item.fields.Teaser?.fields.Link.fields,
    };
    const menuItem: MenuItem = {
      Link: item?.fields?.Link?.fields || undefined,
      SubNavigation,
      Teaser: Teaser?.Image?.Src !== 'undefined' ? Teaser : undefined,
    };
    MainNavigation.push(menuItem);
  });
  const suppliers: Suppliers[] =
    headless.fields.Suppliers?.map((supplier) => ({
      Name: supplier.fields.Name,
      LinkUrl: supplier.fields.LinkUrl,
      LinkLabel: supplier.fields.LinkLabel,
    })) || [];
  return {
    MenuLabel: headless.fields.MenuLabel,
    Logo: {
      Text: headless.fields.LogoText,
      Url: headless.fields.LogoUrl,
      Type: 'default',
      Target: '_self',
    },
    Login: headless.fields.Login?.fields || undefined,
    Search: headless.fields.Search?.fields || undefined,
    MainNavigation,
    OneShopEnabled: config?.OneShopEnabled || false,
    Suppliers: suppliers,
  };
}
