import { UnsubscribeFn } from '@oneaudi/dealer-context-service';
import { EnumerableFootnote } from '@oneaudi/footnote-reference-service';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { IHeaderAction, createSetActiveItemAction } from '../../actions/header-actions';
import { DealerContext } from '../../context';
import { getDealerData } from '../../graphql/get-dealer';
import { AudiHeaderDataManagerProps, Dealer } from '../../interfaces/header-components.interfaces';
import HeaderResponse from '../../interfaces/header-response.interfaces';
import { ExternalDependenciesType } from '../../models/ExternalFaDependencies';
import { HeaderState, headerReducer } from '../../reducers/header-reducer';
import { createUseFootnoteReferenceServiceTextParserHook } from '../../services/hooks';
import { fetchHeaderConfig } from '../../services/navigation-service';
import { cleanNavigationEntries } from '../../utils/clean-navigation-entries';
import { DEALER_CONTEXT_UNSUBSCRIBE, KEY_DEALER_DATA } from '../../utils/globals';
import { isEmpty } from '../../utils/is-empty';
import { normalizeContentHeadless } from '../../utils/normalize-falcon-data';
import { replacePartnerId } from '../../utils/replace-partner';
import AudiHeader from './AudiHeader';

// eslint-disable-next-line max-statements
const AudiHeaderDataManager: React.FC<AudiHeaderDataManagerProps> = ({
  audiMarketContextService,
  authService,
  localeService,
  config,
  ssrHeaderData,
  headerConfigDataUrl,
  headerStateService,
  featureAppId,
  referenceServiceManager,
  layerManager,
  enablePartnerIdReplacement,
  envConfigService,
  dealerContextService,
  contentHeadless,
  eventService,
}) => {
  const isOneCms = contentHeadless?.__type === 'aem-headless';
  const [footnotes, setFootnotes] = useState([]);
  const [partnerId, setPartnerId] = useState(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [headerConfig, setHeaderConfig] = useState<HeaderResponse | undefined>(ssrHeaderData);
  const [renderDealerContext, setRenderDealerContext] = useState<boolean>(
    config?.renderDealerContext || false,
  );
  const [useOneLayer, setUseOneLayer] = useState(config?.useOneLayer || false);
  const [useUserMenu, setUserMenu] = useState(config?.useUserMenu);
  const [dealerData, setDealerData] = useState<Dealer | undefined>(undefined);
  const [externalDependencies] = useState<ExternalDependenciesType>(() => {
    return {
      loginFeatureAppUrl: `https://featureapps.audi.com/audi-feature-app-user-menu/${
        config?.usermenuFeatureAppVersion || '8'
      }/app.js`,
      ...getMiniCartConfiguration(config),
      searchInputFeatureAppUrl: `https://featureapps.audi.com/audi-feature-app-search-input/${
        config?.searchFeatureAppVersion || '5.0.21'
      }/audi-feature-app-search-input.umd.js`,
      searchResultsFeatureAppUrl: `https://featureapps.audi.com/audi-feature-app-search-results/${
        config?.searchFeatureAppVersion || '5.0.21'
      }/audi-feature-app-search-results.umd.js`,
    };
  });

  const useFootnoteReferenceServiceTextParserHook = createUseFootnoteReferenceServiceTextParserHook(
    {
      footnotes,
      referenceServiceManager,
    },
  );

  useEffect(() => {
    (async (): Promise<void> => {
      if (typeof audiMarketContextService !== 'undefined') {
        /* this is needed in order to support both CMS variants. In legacy CMS we are using
         * the ENV Scope (via Market Context Service) in order to retrieve the setting. In
         * headless oneCMS we will need to fetch the setting via Feature App Config instead.
         * */
        await audiMarketContextService
          .initMarketContext()
          .then(() => {
            setUseOneLayer(audiMarketContextService.hasEnvScope('ONEHEADER_ONE_LAYER'));
            setUserMenu(audiMarketContextService.hasEnvScope('ENABLE_USER_MENU'));
            setRenderDealerContext(audiMarketContextService.hasEnvScope('ENABLE_DEALER_CONTEXT'));
          })
          .catch((e) => {
            // when market context service throws an error in headless ignore.
            // fa configuration will be used instead,
            // otherwise the error should be logged as expected
            if (!isOneCms) {
              // eslint-disable-next-line no-console
              console.error('fa-nemo-header', e);
            }
          });
      }
    })();
  }, [audiMarketContextService]);

  useEffect(() => {
    let unsubscribeDealerContext: UnsubscribeFn;
    if (renderDealerContext) {
      unsubscribeDealerContext = dealerContextService.subscribe((dealerContext) => {
        if (dealerContext) {
          const dealerIdFromContext = `${dealerContext?.market}${dealerContext?.brand}${dealerContext?.id}`;
          const store = sessionStorage.getItem(KEY_DEALER_DATA);
          if (store !== DEALER_CONTEXT_UNSUBSCRIBE) {
            const storageData = JSON.parse(store || '{}');
            if (storageData?.dealerId !== dealerIdFromContext) {
              getDealerData(process.env.GRAPHQL_DEALER_ENDPOINT, dealerIdFromContext)
                .then((data) => {
                  if (data) {
                    const { dealerById } = data;
                    setDealerData(dealerById);
                    sessionStorage.setItem(KEY_DEALER_DATA, JSON.stringify(dealerById));
                  }
                })
                .catch(() => {
                  if (!isEmpty(storageData)) {
                    setDealerData(storageData);
                  }
                  // eslint-disable-next-line no-console
                  console.error(`[fa-nemo-header] dealer id ${dealerIdFromContext} not found`);
                });
            } else if (!isEmpty(storageData)) {
              setDealerData(storageData);
            }
          }
        }
      });
    }

    return (): void => {
      if (renderDealerContext) {
        unsubscribeDealerContext();
      }
    };
  }, [dealerContextService, renderDealerContext]);

  useEffect(() => {
    if (window && window.location.href.indexOf('debug=true') !== -1) {
      headerStateService.setDebugMode(true);
    }
    if (referenceServiceManager) {
      referenceServiceManager.registerCallback((_footnotes: EnumerableFootnote[]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return setFootnotes(_footnotes);
      });
    }
    return (): void => {
      if (window) {
        if (window.location.href.indexOf('debug=true') !== -1) {
          headerStateService.setDebugMode(true);
        } else {
          headerStateService.setDebugMode(false);
        }
      }
      if (referenceServiceManager) {
        referenceServiceManager.removeFootnoteReferences();
      }
    };
  }, []);

  useEffect(() => {
    if (!isOneCms) {
      // always load clientside again, to be sure, not to work with a cached version from SSR
      fetchHeaderConfig(headerConfigDataUrl)
        .then((response) => {
          setHeaderConfig(
            cleanNavigationEntries(
              enablePartnerIdReplacement ? replacePartnerId(response, partnerId) : response,
            ),
          );
        })
        // eslint-disable-next-line no-console
        .catch((headerConfigError) => console.error(headerConfigError));
    } else {
      setHeaderConfig(
        enablePartnerIdReplacement
          ? replacePartnerId(normalizeContentHeadless(contentHeadless, config), partnerId)
          : normalizeContentHeadless(contentHeadless, config),
      );
    }
    setIsLoading(false);
  }, [headerConfigDataUrl, ssrHeaderData, partnerId]);

  const [headerState, headerDispatch] = useReducer<React.Reducer<HeaderState, IHeaderAction>>(
    headerReducer,
    {
      activeItem: {
        anchor: null,
        index: -1,
        showSearch: false,
        showDealer: false,
        showLoginFlyout: false,
      },
    },
  );

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      !enablePartnerIdReplacement ||
      !(window.microkernel && window.microkernel.stateRegistry)
    ) {
      return (): void => {
        // Do nothing
      };
    }

    const partnerCallback = (state: React.SetStateAction<undefined>): void => {
      setPartnerId(state);
    };

    window.microkernel.stateRegistry.subscribeToStore('dbadDealerStore', partnerCallback);

    return (): void => {
      window.microkernel.stateRegistry.unsubscribeFromStore('dbadDealerStore', partnerCallback);
    };
  }, [enablePartnerIdReplacement, setPartnerId]);

  const removeDealer = useCallback((): void => {
    setDealerData(undefined);
    sessionStorage.setItem(KEY_DEALER_DATA, DEALER_CONTEXT_UNSUBSCRIBE);
    headerDispatch(
      createSetActiveItemAction({
        anchor: null,
        headerStateService,
        index: -1,
        showSearch: false,
        showDealer: false,
        showLoginFlyout: false,
      }),
    );
  }, [setDealerData]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DealerContext.Provider value={{ renderDealerContext, dealerData, removeDealer }}>
      <AudiHeader
        authService={authService}
        localeService={localeService}
        audiMarketContextService={audiMarketContextService}
        externalFeatureApps={externalDependencies}
        featureAppId={featureAppId}
        headerConfig={headerConfig}
        headerDispatch={headerDispatch}
        headerState={headerState}
        headerStateService={headerStateService}
        isLoading={isLoading}
        layerManager={layerManager}
        useFootnoteReferenceServiceTextParserHook={useFootnoteReferenceServiceTextParserHook}
        useOneLayer={useOneLayer}
        envConfigService={envConfigService}
        useUserMenu={useUserMenu}
        eventService={eventService}
        data-tracking-exclude
      />
    </DealerContext.Provider>
  );
};

export function getMiniCartConfiguration(config: AudiHeaderDataManagerProps['config']) {
  let miniCartFeatureAppBaseUrl =
    'https://featureapps.audi.com/audi-feature-app-oneshop-frontend-mini-cart/5.3.0';
  let miniCartFeatureAppSrc = 'mini-cart.js';
  let oneShopUbffUrl = 'https://www.audi.de/oneshop/proxy/ubff';

  const { miniCartFeatureAppVersion, miniCartFeatureAppSource, ubffEndPointUrl } = config ?? {};

  if (miniCartFeatureAppVersion) {
    const lastMajorMiniCartFeatureAppVersionInFam = 5;
    const versionParts = /^(\d+)\.\d+\.\d+$/.exec(miniCartFeatureAppVersion);

    let version = lastMajorMiniCartFeatureAppVersionInFam;
    if (versionParts) {
      version = parseInt(versionParts[1], 10);
    }

    if (version <= lastMajorMiniCartFeatureAppVersionInFam) {
      miniCartFeatureAppBaseUrl = `https://featureapps.audi.com/audi-feature-app-oneshop-frontend-mini-cart/${miniCartFeatureAppVersion}`;
      miniCartFeatureAppSrc = 'mini-cart.js';
    }
  }

  if (miniCartFeatureAppSource) {
    const newMiniCartFeatureAppSrc = 'mini-cart/mini-cart.js';
    const miniCartHostNameRegex = /^fa-oneshop-frontend\.cdn\.(dev|prod).collab.apps.one.audi$/;
    const parsedMiniCartFeatureAppSource = new URL(miniCartFeatureAppSource);
    if (
      miniCartHostNameRegex.test(parsedMiniCartFeatureAppSource.hostname) &&
      parsedMiniCartFeatureAppSource.pathname.endsWith(newMiniCartFeatureAppSrc)
    ) {
      [miniCartFeatureAppBaseUrl] = miniCartFeatureAppSource.split(`/${newMiniCartFeatureAppSrc}`);
      miniCartFeatureAppSrc = newMiniCartFeatureAppSrc;
    }
  }

  if (ubffEndPointUrl) {
    oneShopUbffUrl = ubffEndPointUrl;
  }

  return {
    miniCartFeatureAppBaseUrl,
    miniCartFeatureAppSrc,
    oneShopUbffUrl,
  };
}

export default AudiHeaderDataManager;
