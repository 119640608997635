import { OpeningHours } from '../interfaces/header-components.interfaces';

export type WeekDay =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';
const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const getCurrentDate = (): Date => new Date();

const displayWeekDay = (dayNumber: number) => {
  return <WeekDay>weekdays[dayNumber];
};

export const getWeekDay = (): WeekDay => {
  const curr = getCurrentDate();
  return displayWeekDay(curr.getDay() - 1);
};

export const timeFormatted = (time?: string): string | undefined => {
  const newTimeFormat = time && time.length > 5 ? time?.substring(0, 5) : time;
  return newTimeFormat?.replace(/^0/, '');
};

const getCurrentTime = () => {
  const date = new Date();
  const minutes = date.getMinutes().toString();
  return `${date.getHours()}:${minutes.length === 1 ? `0${minutes}` : minutes}`;
};

export const isServiceOpen = (
  timeOpen?: string,
  timeClosed?: string,
  currentTime?: string,
): boolean => {
  let now = currentTime || getCurrentTime();
  now = now.length === 4 ? `0${now}` : now;
  let open = timeFormatted(timeOpen);
  open = open?.length === 4 ? `0${open}` : open;
  let closed = timeFormatted(timeClosed);
  closed = closed?.length === 4 ? `0${closed}` : closed;
  return open && closed ? (now >= open && now < closed) || false : false;
};

export const isBeforeOpening = (timeOpen?: string, currentTime?: string): boolean => {
  let now = currentTime || getCurrentTime();
  now = now.length === 4 ? `0${now}` : now;
  let open = timeFormatted(timeOpen);
  open = open?.length === 4 ? `0${open}` : open;
  return open ? now <= open || false : false;
};

export const getNextOpenDay = (week: OpeningHours[], today: OpeningHours) => {
  const restOfTheWeek = week.slice(week.indexOf(today) + 1, week.length);
  const reorderedWeek = restOfTheWeek.concat(week);
  return reorderedWeek.find((weekday) => weekday.timeRanges.length > 0);
};

const getLocale = (locale: string | undefined) => {
  return typeof locale !== 'undefined' ? locale : 'de-DE'; // default to Germany
};

export const weekDayToJsDay = (weekDay: WeekDay): number => weekdays.indexOf(weekDay);

export const createDateWithTime = (baseDate: Date, time?: string): Date => {
  const dayStr = baseDate.getDate() < 10 ? `0${baseDate.getDate()}` : baseDate.getDate();
  const month = baseDate.getMonth() + 1;
  const monthStr = month < 10 ? `0${month}` : month;

  return time ? new Date(`${baseDate.getFullYear()}-${monthStr}-${dayStr}T${time}`) : baseDate;
};

export const getLocalizedWeekdayName = (
  weekDayString: string | undefined,
  locale?: string,
): string => {
  const curr = getCurrentDate();
  // Get the index of the specified day
  const dayIndex = weekdays.indexOf(weekDayString || '');
  // Calculate the difference in days between today and the specified day
  const difference = dayIndex - curr.getDay() + 1; // increment by 1 as start of week in array is Monday
  // If the specified day is today or in the future, add the difference; otherwise, subtract it
  const date = curr;
  date.setDate(curr.getDate() + difference + (difference < 0 ? 7 : 0));
  return new Intl.DateTimeFormat(getLocale(locale), { weekday: 'long' }).format(date);
};

export const getLocalizedTime = (
  timeString: string | undefined,
  timeFormat?: string,
  locale?: string,
): string => {
  if (typeof timeString === 'undefined' || timeString === '') {
    return '';
  }
  const date = createDateWithTime(new Date(), timeString);
  const localizedTime = new Intl.DateTimeFormat(getLocale(locale), {
    hour: 'numeric',
    minute: 'numeric',
  }).format(date);
  return `${localizedTime}${getLocale(locale) !== 'en-US' ? ` ${timeFormat}` : ''}`;
};
